








































import Vue from 'vue'
import Component from 'vue-class-component'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { ClickableIcon }
})
// Used in the job ad overview page (company).
// Card with white title on green background and delete button below on grey background.
// @group SEARCH RESULTS
export default class JobAdCard extends Vue {
  // unique identifier
  @Prop()
  id: string

  // job ad id for deletion and router link
  @Prop()
  jobAdId: string

  // h3 title
  @Prop()
  title: string

  public showPreview (jobAdId: string): void {
    this.$nextTick(() => {
      const lang = this.$i18n.locale.toString()
      const routeData = this.$router.resolve({ name: 'job-ad-preview', params: { id: jobAdId, preview: 'preview' } })
      const target = window.location.origin + '/' + lang + routeData.href
      window.open(target, '_blank')
    })
  }
}
