











































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { JobAdType, JobAdSpecs } from '@/store/jobAd/types'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import JobAdCard from '@/components/search-results/JobAdCard.vue'
import { Action, Getter } from 'vuex-class'
import { SimpleServerResponse, User } from '@/store/types'
import { DELETE_JOB_AD, GET_JOB_ADS } from '@/store/jobAd/actions'
import Rights from '@/common/rights'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
@Component({
  components: { LoadingSpinner, JobAdCard, ConfirmationModal }
})
export default class JobAdOverview extends Vue {
  @Action(GET_JOB_ADS)
  getAllJobAds: (getOwn: boolean) => Promise<Array<JobAdType>>

  @Action(DELETE_JOB_AD)
  deleteAdCall: (specs: JobAdSpecs) => Promise<SimpleServerResponse>

  @Getter('userGetter') userGetter: User

  isLoading = false
  showErrorMessage = false
  errorMessage = ''

  publishedJobAds: JobAdType[] = []
  completedJobAds: JobAdType[] = []
  incompleteJobAds: JobAdType[] = []
  allJobAds: JobAdType[] = []
  selectedId = undefined

  get isOwnJobAd (): boolean {
    return hasRight(Rights.JOB_AD_OWN_WRITE)
  }

  deleteJobAd (): void {
    const specs: JobAdSpecs = {
      id: this.selectedId,
      isOwn: this.isOwnJobAd
    }
    this.$root.$emit('load')
    this.deleteAdCall(specs).then(() => {
      this.selectedId = undefined
      this.loadJobAds()
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  loadJobAds (): void {
    this.isLoading = true
    this.getAllJobAds(this.isOwnJobAd).then(data => {
      this.allJobAds = data
      this.completedJobAds = data.filter(jobAd => {
        return jobAd.status === Enums.JOB_AD_STATUS_COMPLETED_NOT_PUBLISHED
      })
      this.publishedJobAds = data.filter(jobAd => {
        return jobAd.status === Enums.JOB_AD_STATUS_PUBLISHED
      })
      this.incompleteJobAds = data.filter(jobAd => {
        return jobAd.status === Enums.JOB_AD_STATUS_INCOMPLETE
      })
    }).catch(error => {
      if (error.status !== 409) {
        this.errorMessage = error
        this.showErrorMessage = true
      }
    }).finally(() => {
      this.isLoading = false
    })
  }

  mounted (): void {
    this.loadJobAds()
  }
}
